<template>
  <df-modal
    persistent
    :action-name="modalActionName"
    :loading="loading"
    :title="modalTitle"
    @action-click="programAction"
    @close="closeAddProgramModal"
  >
    <template #card-content>
      <v-form ref="form" class="add-program-modal">
        <div class="df-flex-xl df-flex-col">
          <div class="df-flex-xxl df-flex-col">
            <div v-if="!programId" class="df-flex-xxl df-flex-col-m">
              <div class="df-col-6 df-col-12-m">
                <df-filter-select
                  v-model="filteredField"
                  search-bar
                  width="100%"
                  :disabled="!fields.length"
                  :items="fields"
                  :placeholder="$t('DiseasesControl.header.select_field')"
                  :search-placeholder="
                    $t('DiseasesControl.header.search_field')
                  "
                  @click="requestVarieties"
                />
              </div>
              <div class="df-col-6 df-col-12-m">
                <df-filter-select
                  v-model="filteredVariety"
                  search-bar
                  width="100%"
                  :disabled="shouldDisableVarietiesFilter"
                  :items="varieties"
                  :loading="loadingVarieties"
                  :placeholder="$t('DiseasesControl.header.select_variety')"
                  :search-placeholder="
                    $t('DiseasesControl.header.search_variety')
                  "
                />
              </div>
            </div>
            <df-input
              v-model="programName"
              :label="$t('DiseasesControl.add_program_modal.program_name')"
              :placeholder="
                $t('DiseasesControl.add_program_modal.insert_program_name')
              "
              :rules="[required]"
            />
            <df-date-picker
              v-model="sowingEstimatedDate"
              :events="sowingDateEvents"
              :hint="
                $t('DiseasesControl.add_program_modal.estimated_sowing_hint')
              "
              :label="
                $t('DiseasesControl.add_program_modal.estimated_sowing_date')
              "
              :loading="loadingDates"
              :max="max"
              :min="min"
              :placeholder="$t('DiseasesControl.add_program_modal.select_date')"
              :rules="[required]"
              @month-change="getPlanningSowings"
            >
              <template #caption>
                <div class="df-flex-xm df-flex-col align-start date__caption">
                  <div class="df-flex-m align-center justify-end">
                    <div class="green__circle"></div>
                    <p>
                      {{
                        $t(
                          'DiseasesControl.add_program_modal.registered_sowing_date'
                        )
                      }}
                    </p>
                  </div>
                  <div class="df-flex-m align-center justify-end">
                    <div class="yellow__circle"></div>
                    <p>
                      {{
                        $t(
                          'DiseasesControl.add_program_modal.date_not_recommended'
                        )
                      }}
                    </p>
                  </div>
                </div>
              </template>
            </df-date-picker>
            <div class="df-flex-sm flex-nowrap modal__warning">
              <font-awesome-icon color="#F2CD12" icon="exclamation-triangle" />
              <div>
                <span>
                  {{
                    $t(
                      'DiseasesControl.add_program_modal.date_selection_warning'
                    )
                  }}
                </span>
                <span v-if="programId">
                  <br />
                  {{
                    $t(
                      'DiseasesControl.add_program_modal.edit_date_selection_warning'
                    )
                  }}
                </span>
              </div>
            </div>
            <df-radio-button
              v-model="expectedPressure"
              :label="$t('DiseasesControl.add_program_modal.expected_pressure')"
              :options="pressureOptions"
            />
          </div>
          <v-divider />
          <div class="df-flex-l df-flex-col">
            <h2>{{ $t('DiseasesControl.add_program_modal.diseases') }}</h2>
            <p class="modal__description">
              {{ $t('DiseasesControl.add_program_modal.diseases_description') }}
            </p>
            <div class="df-flex-sm df-flex-l-m df-flex-col-m">
              <div class="df-col-6">
                <df-radio-button
                  v-model="whiteMoldControl"
                  :label="
                    $t('DiseasesControl.add_program_modal.white_mold_control')
                  "
                  :options="whiteMoldOptions"
                />
              </div>
              <div class="df-col-6">
                <df-radio-button
                  v-model="aerialWebBlightControl"
                  :label="
                    $t(
                      'DiseasesControl.add_program_modal.aerial_web_blight_control'
                    )
                  "
                  :options="aerialWebBlightOptions"
                />
              </div>
            </div>
          </div>
        </div>
      </v-form>
    </template>
  </df-modal>
</template>

<script>
import DfDatePicker from '@/lib/components/DatePicker/DfDatePicker.vue'
import DfFilterSelect from '@/lib/components/FilterSelect/DfFilterSelect.vue'
import DfInput from '@/lib/components/Input/DfInput.vue'
import DfModal from '@/lib/Modal/DfModal.vue'
import DfRadioButton from '@/lib/components/Button/DfRadioButton.vue'
import diseasesControlService from '@/modules/diseasesControl/services/diseasesControlService.js'
import moment from 'moment'
import sowingsPlanningService from '@/modules/sowingsPlanning/services/sowingsPlanningService.js'
import { required } from '@/utils/formValidations'
import { logEvent, events } from '@/services/analytics'

export default {
  name: 'AddProgramModal',

  components: {
    DfDatePicker,
    DfFilterSelect,
    DfInput,
    DfModal,
    DfRadioButton,
  },

  props: {
    programId: {
      default: null,
      type: Number,
    },
  },

  data() {
    return {
      aerialWebBlightControl: '',
      crop: {},
      daysWithPlanningSowing: [],
      exceptionDates: [],
      expectedPressure: '',
      fields: [],
      filteredField: {},
      filteredVariety: {},
      loading: false,
      loadingDates: false,
      loadingVarieties: false,
      max: '',
      min: '',
      programName: '',
      required,
      varieties: [],
      sowingEstimatedDate: '',
      whiteMoldControl: '',
    }
  },

  inject: {
    requestPrograms: {
      default: () => [],
      from: 'requestPrograms',
    },
  },

  async created() {
    this.$root.$on('diseases-control-params', this.updateData)
    this.$root.$emit('request-program-data')
    if (this.programId) {
      this.loading = true
      const params = {
        program_id: this.programId,
      }
      try {
        const { data } = await diseasesControlService.getProgramInfos(params)
        this.programName = data.name
        this.sowingEstimatedDate = data.estimated_sowing_date
        this.expectedPressure = data.expected_pressure
        this.whiteMoldControl = data.white_mold_control
        this.aerialWebBlightControl = data.aerial_web_blight_control
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  },

  computed: {
    aerialWebBlightOptions() {
      return [
        {
          label: this.$t('DiseasesControl.add_program_modal.no'),
          value: 'no',
        },
        {
          label: this.$t('DiseasesControl.add_program_modal.yes'),
          value: 'yes',
        },
      ]
    },
    modalActionName() {
      return this.programId
        ? this.$t('DiseasesControl.add_program_modal.save')
        : this.$t('DiseasesControl.add_program_modal.create_program')
    },
    modalTitle() {
      return this.programId
        ? this.$t('DiseasesControl.add_program_modal.edit_program')
        : this.$t('DiseasesControl.add_program_modal.new_program')
    },
    pressureOptions() {
      return [
        {
          label: this.$t('DiseasesControl.add_program_modal.high'),
          value: 'high',
        },
        {
          label: this.$t('DiseasesControl.add_program_modal.medium'),
          value: 'medium',
        },
        {
          label: this.$t('DiseasesControl.add_program_modal.low'),
          value: 'low',
        },
      ]
    },
    programAction() {
      return this.programId ? this.editProgram : this.addProgram
    },
    shouldDisableVarietiesFilter() {
      return !this.filteredField.value || !this.varieties.length
    },
    whiteMoldOptions() {
      return [
        {
          label: this.$t('DiseasesControl.add_program_modal.no'),
          value: 'no',
        },
        {
          label: this.$t('DiseasesControl.add_program_modal.yes'),
          value: 'yes',
        },
      ]
    },
  },

  methods: {
    async addProgram() {
      if (!this.$refs.form.validate()) return
      logEvent(events.diseasesControlModule.DiseasesControl.finish_new_program)
      this.loading = true
      const params = {
        aerial_web_blight_control: this.aerialWebBlightControl,
        crop: this.crop,
        expected_pressure: this.expectedPressure,
        field_id: Number(this.filteredField.value),
        program_name: this.programName,
        estimated_sowing_date: this.sowingEstimatedDate,
        variety: {
          id: Number(this.filteredVariety.value),
          name: this.filteredVariety.name.split(' | ')[1],
        },
        white_mold_control: this.whiteMoldControl,
      }
      try {
        await diseasesControlService.createProgram(params)
        this.$root.$emit(
          'notify',
          'success',
          this.$t(
            'DiseasesControl.add_program_modal.successful_program_created'
          )
        )
        this.$root.$emit('add-program', {
          field: this.filteredField,
          variety: this.filteredVariety,
        })
        this.closeAddProgramModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    closeAddProgramModal() {
      this.$emit('close')
    },
    async editProgram() {
      if (!this.$refs.form.validate()) return
      this.loading = true
      const params = {
        aerial_web_blight_control: this.aerialWebBlightControl,
        expected_pressure: this.expectedPressure,
        program_name: this.programName,
        estimated_sowing_date: this.sowingEstimatedDate,
        program_id: this.programId,
        white_mold_control: this.whiteMoldControl,
      }
      try {
        await diseasesControlService.editProgram(params)
        this.requestPrograms()
        this.closeAddProgramModal()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async requestVarieties() {
      this.loadingVarieties = true
      const params = {
        field_id: this.filteredField.value,
      }
      try {
        const { data } = await diseasesControlService.getSowingsVarieties(
          params
        )
        const varietyIds = []
        this.varieties = data.reduce((acc, variety) => {
          if (!varietyIds.includes(variety.id)) {
            acc.push({
              name: `${this.$t(
                `SowingsPlanning.variety_list_item.${variety.crop_message_key}`
              )} | ${variety.name}`,
              value: String(variety.id),
            })
            varietyIds.push(variety.id)
          }
          return acc
        }, [])
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingVarieties = false
      }
    },
    async getPlanningSowings(date) {
      this.loadingDates = true
      const dateInfos = date.split('-')
      const params = {
        field_id: Number(this.filteredField.value),
        variety_id: Number(this.filteredVariety.value),
        year_month: dateInfos[0] + '-' + dateInfos[1],
      }
      this.daysWithPlanningSowing = []
      try {
        const { data } = await sowingsPlanningService.getPlanningSowings(params)
        data.forEach((sowing) => {
          const day = sowing.estimated_date.split('-')[2]
          this.daysWithPlanningSowing.push(day)
        })
      } catch (error) {
        console.error(error)
      } finally {
        this.loadingDates = false
      }
    },
    sowingDateEvents(date) {
      const day = date.split('-')[2]
      const hasSowing = this.daysWithPlanningSowing.includes(day)

      const sowingStart = this.exceptionDates[0]
      const sowingEnd = this.exceptionDates[1]

      const momentSowingStart = moment(sowingStart, 'YYYY-MM-DD')
      const momentSowingEnd = moment(sowingEnd, 'YYYY-MM-DD')

      const isInRecommendedInterval = moment(date).isBetween(
        momentSowingStart,
        momentSowingEnd,
        null,
        '[]'
      )
      // CM: validacao a ser feita depois
      const allowedDates = moment(date).isBetween(
        this.min,
        this.max,
        null,
        '[]'
      )

      if (hasSowing && (!isInRecommendedInterval || !allowedDates))
        return ['#F2CD12', '#39AF49']
      else if (hasSowing) return '#39AF49'
      else if (!isInRecommendedInterval || !allowedDates) return '#F2CD12'
    },
    updateData(data) {
      this.crop.id = data.crop_id
      this.crop.message_key = data.crop_message_key
      this.fields = data.fields
      this.filteredField = data.filtered_field
      this.filteredVariety = data.filtered_variety
      this.varieties = data.varieties
    },
  },

  beforeDestroy() {
    this.$root.$off('diseases-control-params', this.updateData)
  },
}
</script>

<style lang="scss" scoped>
.add-program-modal {
  h2 {
    color: #1a2b46;
    font-family: 'Rubik';
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
  }
  .modal__warning {
    padding: 16px;
    border-radius: 4px;
    border: 1px solid #fff2b4;
    background-color: #fffdf5;

    span {
      color: #5b6459;
      font-family: 'Rubik';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .modal__description {
    padding: 16px;
    background-color: #f8f9f8;
    border-radius: 4px;
    color: #5b6459;
    font-family: 'Rubik';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
.date__caption {
  .green__circle {
    height: 4px;
    width: 4px;
    border-radius: 9999px;
    background-color: #39af49;
  }
  .yellow__circle {
    height: 4px;
    width: 4px;
    border-radius: 9999px;
    background-color: #f2cd12;
  }
  p {
    color: #5b6459;
    text-align: center;
    font-family: 'Rubik';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
</style>
